import paymentTypeForB2C from "@/components/payment/mixins/paymentTypeForB2C.js";

export default {
  mixins: [paymentTypeForB2C],
  components: {
    itemCard: () => import("@/modules/base/components/itemCard/itemCard.vue"),
  },
  computed: {
    statusColorClass() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
      )
        return "red--text";
      return "primary--text";
    },
    actionText() {
      return this.$t(`order.status.${this.data.status}`);
    },
    cardType() {
      switch (this.data.status) {
        case "finished":
          return "lighten";
        case "reject":
        case "canceled":
          return "error";
        case "request_return":
          return "dark";
        default:
          return "secondary";
      }
    },
    paymentStatusColorClass() {
      if (this.data.payment_status == "UNPAY")
        return "red--text text-right pr-md-5 pr-sm-3 pr-2 text-md-subtitle-2 body-2 font-weight-black d-flex justify-end align-start";
      return "";
    },
    checkPaymentStatus() {
      if (!this.data.payment_type && !this.data.payment_status) return null;
      return this.thirdPartyPaymentTypes.includes(this.data.payment_type) &&
        this.data.payment_status == "UNPAY"
        ? this.$t("apply-pay.status.pending")
        : null;
    },
  },
};
