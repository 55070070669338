<template>
  <div class="d-flex flex-row align-center">
    <v-checkbox
      v-if="hasSelectFeature"
      @change="toggleSelected"
      v-model="selected"
      :disabled="disabledSelect"
    />
    <itemCard
      :type="cardType"
      :data="computedData"
      :action="action"
      :actionText="actionText"
    />
  </div>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listRow from "@/components/list/mixins/listRow";
import orderCardItemMixins from "@/mixins/orderCardItem.js";
import dayjs from "dayjs";
export default {
  mixins: [listItem, listRow, orderCardItemMixins],
  data: () => {
    return {
      useData: true,
    };
  },
  computed: {
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`];
    },
    computedData() {
      return [
        {
          text: "",
          value: this.data.store_name,
          fixCol: 5,
        },
        {
          text: "",
          value: this.$helper.orderTimeFormat(this.data.created_at),
          class: this.statusColorClass,
          fixCol: 4,
        },
        {
          text: "",
          value: this.checkPaymentStatus,
          class: this.paymentStatusColorClass,
          iconClass: 'red--text',
          iconText: 'fa-exclamation-circle',
          fixCol: 3,
        },
        {
          text: "",
          value:
            `NT$${this.data.total_amounts}` +
            (this.data.items
              ? `（${this.data.items.reduce(
                  (a, b) => a + b.count,
                  0
                )}項商品：${this.data.items
                  .map((v) => v.product + "*" + v.count)
                  .join(", ")}）`
              : ""),
        },
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
      ];
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "order-detail",
        params: {
          orderId: this.data.order_no,
          providerId: this.$store.getters[`member/providerId`],
        },
        query:{
          createPageDatetime: dayjs().format("YYYY-MM-DD HH:mm:ss")
        }
      });
    },
  },
};
</script>
